import { AttendeeRole, CNA_TYPE_OTHER_TEXT_CHAR_LIMIT } from '@/constants'
import { ReporterFeedback, TranscriptOrders } from '@/types'
import { COUNTIES, STATES_CODES } from '@/utils/states'
import { isValidEmail } from '@/utils/validations'

export const reporterStateValidation = (reporterState: string | null) => {
  if (reporterState !== null && STATES_CODES.includes(reporterState)) {
    return null
  }
  return 'Please pick valid state'
}

export const reporterCountyValidation = (
  county: string | null,
  values: ReporterFeedback,
) => {
  if (
    county !== null &&
    values !== undefined &&
    values.reporter_state !== null &&
    STATES_CODES.includes(values.reporter_state) &&
    COUNTIES[values.reporter_state].includes(county)
  ) {
    return null
  }
  return 'Please pick valid county'
}

export const transcriptOrderedValidation = (
  transcriptOrdered: boolean | null,
  values: ReporterFeedback,
  context: {
    orderRequired: boolean
  },
) => {
  if (
    context.orderRequired &&
    values.event_status === 'completed' &&
    !transcriptOrdered
  ) {
    return 'Certified transcript must be ordered for completed event'
  }

  if (values.event_status !== 'completed' && transcriptOrdered) {
    return "Certified transcript can't be ordered for not completed event"
  }

  return null
}

export const turnaroundHoursValidation = (
  turnaroundHours: number | null,
  values: ReporterFeedback,
  canOrderForOc: boolean,
) => {
  if (!values?.transcript_ordered || canOrderForOc) {
    return null
  }

  if (turnaroundHours !== null) {
    return null
  }

  return 'Please pick turnaround hours'
}

export const transcriptOrdersValidation = (
  transcriptOrders: TranscriptOrders | null,
  values: ReporterFeedback,
  context: {
    canOrderForOc: boolean
    isOrderRequired: boolean
  },
) => {
  if (!context.canOrderForOc) {
    return null
  }

  if (!values?.transcript_ordered) {
    return null
  }

  const orders = Object.values(transcriptOrders ?? {})

  // for court reporter events all attorneys must order transcript
  // for other events only some attorneys can order transcript
  if (!context.isOrderRequired) {
    // there should be at least one order, otherwise the `transcript_ordered` should be false
    if (orders.some(order => order.turnaround_hours !== null)) {
      return null
    }
  } else {
    if (orders.every(order => order.turnaround_hours !== null)) {
      return null
    }
  }

  return 'Please fill out a transcription order'
}

export const cnaDeclaredAtValidation = (
  canDeclaredAt: string | null,
  values: ReporterFeedback,
) => {
  if (values?.event_status !== 'canceled_cna' || canDeclaredAt !== null) {
    return null
  }

  return 'Please pick valid time'
}

export const cnaTypeValidation = (
  cnaType: string | null,
  values: ReporterFeedback,
) => {
  if (values?.event_status !== 'canceled_cna') {
    return null
  }

  if (cnaType !== null) {
    return null
  }

  return 'Please pick type of CNA certificate'
}

export const cnaTypeOtherTextValidation = (
  cnaTypeText: string | null,
  values: ReporterFeedback,
) => {
  if (values?.event_status !== 'canceled_cna' || values?.cna_type !== 'other') {
    return null
  }

  if (
    cnaTypeText !== null &&
    cnaTypeText.length > 0 &&
    cnaTypeText.length <= CNA_TYPE_OTHER_TEXT_CHAR_LIMIT
  ) {
    return null
  }

  return 'Please specify the reason for the CNA certificate'
}

export const nameValidation = (name: string | null) => {
  if (name === null || name.length === 0) {
    return 'Please enter name'
  }

  // every word in name must contain an uppercase letter, or be & symbol
  const nameWordPattern = /(^&$)|\p{Uppercase_Letter}/u
  if (
    !name
      .trim()
      .split(/\s+/)
      .every(w => nameWordPattern.test(w))
  ) {
    return 'Please enter correctly capitalized name'
  }

  return null
}

export const emailValidation = (email: string | null) => {
  if (email === null || email.length === 0) {
    return null
  }

  if (!isValidEmail(email!)) {
    return 'Invalid email'
  }
}

export const uniqueEmailValidation = (
  email: string | null,
  values: ReporterFeedback,
) => {
  const isValid = emailValidation(email)

  if (isValid) {
    return isValidEmail
  }

  const isNotUnique =
    values.attendees.filter(a => (email ? a.email === email : false)).length > 1

  if (isNotUnique) {
    return 'Email is already in use'
  }

  return null
}

export const roleValidation = (role: AttendeeRole | null) => {
  if (!role || !Object.values(AttendeeRole).includes(role)) {
    return 'Please pick a role'
  }

  return null
}

export const presentValidation = (present: boolean | null) => {
  if (present === null) {
    return 'Pick one'
  }

  return null
}
