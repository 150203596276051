import React from 'react'
import {
  Button,
  Input,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'

import { MIME_TYPES, UploadedFile, Uploader } from './common/Uploader'

import { emailValidation } from '@/components/Event/Feedback/validation'
import { StateSelect } from '@/components/StateSelect'
import { useAuth } from '@/hooks/useAuth'
import { useForm } from '@/hooks/useForm'
import {
  UpdateReporterPayload,
  useUpdateReporterMutation,
} from '@/queries/reporters'
import { Reporter, ReporterBase } from '@/types'
import { isCommissionNumberRequired } from '@/utils/parrot'
import { isValidPhoneNumber } from '@/utils/phoneNumber'
import { COUNTIES, STATES_CODES } from '@/utils/states'

const phoneValidation = (value?: string) => {
  if (
    value?.length === 0 ||
    (value !== undefined && isValidPhoneNumber(value))
  ) {
    return null
  }

  return 'Please enter a valid phone number'
}

const nameValidation = (value: string) => {
  if (value.length === 0) {
    return 'Please enter a name'
  }

  return null
}

const stateValidation = (value: string | undefined) => {
  if (!value || !STATES_CODES.includes(value)) {
    return 'Please select state'
  }

  return null
}

const commissionNumberValidation = (
  value: string | undefined,
  values: UpdateReporterPayload,
) => {
  if (isCommissionNumberRequired(values.state) && !value) {
    return 'Please enter commission number'
  }

  return null
}

const commissionExpiryValidation = (value: string | undefined) => {
  if (!value) {
    return 'Please enter commission expiration date'
  }

  return null
}

const commissionLicensesValidation = (value: UploadedFile[]) => {
  if (value.length === 0) {
    return 'Please upload commission license'
  }

  return null
}

export const ReporterSettings: React.FC<{
  reporter: Reporter
  onUpdated: (reporter: ReporterBase) => void
  wide: boolean
}> = ({ reporter, onUpdated, wide }) => {
  const { mutateAsync } = useUpdateReporterMutation(reporter, onUpdated)
  const { user } = useAuth()

  const form = useForm<UpdateReporterPayload>({
    initialValues: {
      name: reporter.name,
      email: reporter.email,
      phone_number: reporter.phone_number ?? '',
      state: reporter.state ?? '',
      county: reporter.county ?? '',
      commission_number: reporter.commission_number ?? '',
      commission_expiry: reporter.commission_expiry,
      commission_licenses: reporter.commission_licenses,
      certificates: reporter.certificates,
    },

    validate: {
      name: nameValidation,
      email: emailValidation,
      phone_number: phoneValidation,
      state: stateValidation,
      ...(reporter.needs_commission
        ? {
            commission_number: commissionNumberValidation,
            commission_expiry: commissionExpiryValidation,
            commission_licenses: commissionLicensesValidation,
          }
        : {}),
    },
  })

  // if the reporter is updating himself and if he will change his email then
  // he will receive a verification email
  const isReporterUpdatingHimself =
    user?.role === 'reporter' && user?.id === reporter.id
  const emailChanged = form.values.email !== reporter.email

  return (
    <div>
      <form onSubmit={form.onSubmit(mutateAsync)} className="space-y-8">
        <div className="flex items-start">
          {wide && (
            <div className="flex-1">
              <div className="pr-16">
                <Title mb="sm" order={6}>
                  Contact information
                </Title>

                <Text size="sm" sx={theme => ({ color: theme.colors.gray[7] })}>
                  This information will be used to send you notifications about
                  bookings and upcoming events.
                </Text>
              </div>
            </div>
          )}

          <Stack className="flex-1">
            <TextInput
              required
              label="Name"
              error={form.errors.name}
              value={form.values.name}
              onChange={event =>
                form.setFieldValue('name', event.currentTarget.value)
              }
            />

            <TextInput
              required
              label="Email"
              error={form.errors.email}
              value={form.values.email}
              onChange={event =>
                form.setFieldValue('email', event.currentTarget.value)
              }
              description={
                emailChanged &&
                isReporterUpdatingHimself &&
                'We will send you a verification email'
              }
            />

            <TextInput
              required
              label="Phone number"
              error={form.errors.phone_number}
              value={form.values.phone_number}
              onChange={event =>
                form.setFieldValue('phone_number', event.currentTarget.value)
              }
            />
            <StateSelect
              required
              label="State"
              placeholder="Pick one"
              error={form.errors.state}
              value={form.values.state}
              states={STATES_CODES}
              onChange={value => {
                form.setFieldValue('state', value || '')
                form.setFieldValue('county', '')
              }}
            />
          </Stack>
        </div>

        {reporter.needs_commission && (
          <div className="flex items-start">
            {wide && (
              <div className="flex-1">
                <div className="pr-16">
                  <Title order={6} mb="sm">
                    Commission info
                  </Title>

                  <Text
                    size="sm"
                    sx={theme => ({ color: theme.colors.gray[7] })}
                  >
                    This information is used to filter events which you can book
                    and to generate certificates after event is completed.
                  </Text>
                </div>
              </div>
            )}

            <Stack className="flex-1">
              <Select
                label="County"
                placeholder="Pick one"
                error={form.errors.county}
                value={form.values.county}
                onChange={value => form.setFieldValue('county', value || '')}
                data={
                  form.values.state
                    ? COUNTIES[form.values.state].map(state => ({
                        value: state,
                        label: state,
                      }))
                    : []
                }
                disabled={!form.values.state}
                searchable
              />

              {form.values.state &&
                isCommissionNumberRequired(form.values.state) && (
                  <TextInput
                    required
                    label="Commission number"
                    error={form.errors.commission_number}
                    value={form.values.commission_number}
                    onChange={event =>
                      form.setFieldValue(
                        'commission_number',
                        event.currentTarget.value,
                      )
                    }
                  />
                )}

              <DatePickerInput
                required
                label="Commission expiration date"
                placeholder="Pick date"
                error={form.errors.commission_expiry}
                value={
                  form.values.commission_expiry
                    ? parseISO(form.values.commission_expiry)
                    : undefined
                }
                onChange={date =>
                  form.setFieldValue(
                    'commission_expiry',
                    date
                      ? formatISO(date, { representation: 'date' })
                      : undefined,
                  )
                }
              />

              <Input.Wrapper
                required
                id="input-commission-license"
                label="Commission license"
                description="Supported are PNG, PDF, JPEG. Max size 10MB"
                error={form.errors.commission_licenses}
              >
                <Uploader
                  inputId="input-commission-license"
                  accept={[MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.PDF]}
                  maxSize={10 * 10 ** 6} // 10MB
                  presignUrl={reporter.presign_commission_license_upload_url}
                  files={form.values.commission_licenses}
                  onChange={files =>
                    form.setFieldValue('commission_licenses', files)
                  }
                />
              </Input.Wrapper>

              <Input.Wrapper
                id="input-certificate"
                label="E-notary"
                description="Supported are PNG, PDF, JPEG. Max size 10MB"
                error={form.errors.certificates}
              >
                <Uploader
                  inputId="input-certificate"
                  accept={[MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.PDF]}
                  maxSize={10 * 10 ** 6} // 10MB
                  presignUrl={reporter.presign_certificate_upload_url}
                  files={form.values.certificates}
                  onChange={files => form.setFieldValue('certificates', files)}
                />
              </Input.Wrapper>
            </Stack>
          </div>
        )}

        <div className="flex items-start">
          {wide && <div className="flex-1" />}
          <div className="flex-1">
            <Button type="submit" loading={form.submitting} fullWidth>
              Save changes
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
