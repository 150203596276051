export enum ReasonForCna {
  WITNESS_NO_SHOW = 'witness_no_show',
  WITNESS_AND_CLIENT_NO_SHOW = 'witness_and_client_no_show',
  OPPOSING_COUNSEL_NO_SHOW = 'opposing_counsel_no_show',
  OTHER = 'other',
}

export const REASON_FOR_CNA_LABEL = {
  [ReasonForCna.WITNESS_NO_SHOW]: 'Witness no-show',
  [ReasonForCna.WITNESS_AND_CLIENT_NO_SHOW]: 'Witness and Client No-Show',
  [ReasonForCna.OPPOSING_COUNSEL_NO_SHOW]: 'Opposing Counsel No-Show',
  [ReasonForCna.OTHER]: 'Other (All attendees are present)',
}

export enum DepositionResult {
  RESCHEDULED = 'rescheduled',
  CANCELLED = 'canceled', // there's typo in the backend
  CNA = 'canceled_cna',
  RESET = 'reset',
  COMPLETED = 'completed',
}

export enum LowConfidenceWordStatus {
  UNRESOLVED = 'unresolved',
  CORRECTED = 'corrected',
  UNCLEAR = 'unclear',
}

export const DEPOSITION_ENDED_OPTIONS_LABEL = {
  [DepositionResult.RESCHEDULED]: 'Rescheduled',
  [DepositionResult.CANCELLED]: 'Cancelled',
  [DepositionResult.CNA]: 'CNA',
  [DepositionResult.RESET]: 'Reset',
  [DepositionResult.COMPLETED]: 'Completed',
}

export enum AttendeeRole {
  ARBITRATOR = 'arbitrator',
  CLERK_OF_COURT = 'clerk_of_court',
  COURT_REPORTER = 'court_reporter',
  DIGITAL_REPORTER = 'digital_reporter',
  INTERPRETER = 'interpreter',
  INVESTIGATOR = 'investigator',
  PLAINTIFF_ATTORNEY = 'plaintiff_attorney',
  DEFENDANT_ATTORNEY = 'defendant_attorney',
  WITNESS_ATTORNEY = 'witness_attorney',
  JUDGE = 'judge',
  OPPOSING_COUNSEL = 'opposing_counsel',
  STAFF_COUNSEL = 'staff_counsel',
  WITNESS = 'witness',
  OTHER = 'other',
  GUARDIAN = 'guardian_for_the_witness',
}

export enum TimestampType {
  RECORDING_ON = 'on the record',
  RECORDING_OFF = 'off the record',
  EXHIBIT = 'exhibit',
}

export enum TurnaroundHours {
  ROUGH_DRAFT = 0,
  ONE_DAY = 24,
  THREE_DAYS = 72,
  SEVEN_DAYS = 168,
}

export const TURNAROUND_TIME_LABEL = {
  [TurnaroundHours.ROUGH_DRAFT.toString()]: 'Certified transcript not ordered',
  [TurnaroundHours.SEVEN_DAYS.toString()]: 'Standard: 7–10 Business Days',
  [TurnaroundHours.THREE_DAYS.toString()]: 'Faster: 3–5 Business Days',
  [TurnaroundHours.ONE_DAY.toString()]: 'Surge: 1 Business Day',
}

export const TURNAROUND_TIME_OPTIONS = [
  {
    label: TURNAROUND_TIME_LABEL[TurnaroundHours.ROUGH_DRAFT.toString()],
    value: TurnaroundHours.ROUGH_DRAFT.toString(),
  },
  {
    label: TURNAROUND_TIME_LABEL[TurnaroundHours.SEVEN_DAYS.toString()],
    value: TurnaroundHours.SEVEN_DAYS.toString(),
  },
  {
    label: TURNAROUND_TIME_LABEL[TurnaroundHours.THREE_DAYS.toString()],
    value: TurnaroundHours.THREE_DAYS.toString(),
  },
  {
    label: TURNAROUND_TIME_LABEL[TurnaroundHours.ONE_DAY.toString()],
    value: TurnaroundHours.ONE_DAY.toString(),
  },
]

export enum IdentityDocumentType {
  PASSPORT = 'passport',
  DRIVER_LICENSE = 'drivers_license',
  STATE_ID = 'state_id',
}

export const IDENTITY_DOCUMENT_TYPE_LABEL = {
  [IdentityDocumentType.PASSPORT]: 'Passport',
  [IdentityDocumentType.DRIVER_LICENSE]: 'Driver License',
  [IdentityDocumentType.STATE_ID]: 'State ID',
}

export const IDENTITY_DOCUMENT_TYPE_OPTIONS = [
  {
    label: IDENTITY_DOCUMENT_TYPE_LABEL[IdentityDocumentType.PASSPORT],
    value: IdentityDocumentType.PASSPORT,
  },
  {
    label: IDENTITY_DOCUMENT_TYPE_LABEL[IdentityDocumentType.DRIVER_LICENSE],
    value: IdentityDocumentType.DRIVER_LICENSE,
  },
  {
    label: IDENTITY_DOCUMENT_TYPE_LABEL[IdentityDocumentType.STATE_ID],
    value: IdentityDocumentType.STATE_ID,
  },
]

export enum IdentityDocumentStatus {
  VALID = 'valid',
  EXPIRED = 'expired',
  NOT_PRESENT = 'not_present',
  STIPULATE = 'stipulate',
}

export const IDENTITY_DOCUMENT_STATUS_LABEL = {
  [IdentityDocumentStatus.VALID]: 'Valid',
  [IdentityDocumentStatus.STIPULATE]: 'Stipulate',
  [IdentityDocumentStatus.EXPIRED]: 'Expired',
  [IdentityDocumentStatus.NOT_PRESENT]: 'Not Present',
}

export const IDENTITY_DOCUMENT_STATUS_OPTIONS = [
  {
    label: IDENTITY_DOCUMENT_STATUS_LABEL[IdentityDocumentStatus.VALID],
    value: IdentityDocumentStatus.VALID,
  },
  {
    label: IDENTITY_DOCUMENT_STATUS_LABEL[IdentityDocumentStatus.EXPIRED],
    value: IdentityDocumentStatus.EXPIRED,
  },
  {
    label: IDENTITY_DOCUMENT_STATUS_LABEL[IdentityDocumentStatus.NOT_PRESENT],
    value: IdentityDocumentStatus.NOT_PRESENT,
  },
  {
    label: IDENTITY_DOCUMENT_STATUS_LABEL[IdentityDocumentStatus.STIPULATE],
    value: IdentityDocumentStatus.STIPULATE,
  },
]

export const TIMESTAMP_MESSAGES = {
  [TimestampType.RECORDING_ON]: 'on the record',
  [TimestampType.RECORDING_OFF]: 'off the record',
  [TimestampType.EXHIBIT]: 'Exhibit',
}

export const ATTENDEE_ROLE_LABEL = {
  [AttendeeRole.ARBITRATOR]: 'Arbitrator',
  [AttendeeRole.CLERK_OF_COURT]: 'Clerk of Court',
  [AttendeeRole.COURT_REPORTER]: 'Court Reporter',
  [AttendeeRole.DIGITAL_REPORTER]: 'Digital Court Reporter',
  [AttendeeRole.INTERPRETER]: 'Interpreter',
  [AttendeeRole.INVESTIGATOR]: 'Investigator',
  [AttendeeRole.PLAINTIFF_ATTORNEY]: 'Attorney for the Plaintiff',
  [AttendeeRole.DEFENDANT_ATTORNEY]: 'Attorney for the Defendant',
  [AttendeeRole.WITNESS_ATTORNEY]: 'Attorney for the Witness',
  [AttendeeRole.JUDGE]: 'Judge',
  [AttendeeRole.OPPOSING_COUNSEL]: 'Opposing Counsel',
  [AttendeeRole.STAFF_COUNSEL]: 'Staff Counsel',
  [AttendeeRole.WITNESS]: 'Witness',
  [AttendeeRole.OTHER]: 'Other',
  [AttendeeRole.GUARDIAN]: 'Guardian for the Witness',
}

export enum EventType {
  DEPOSITION = 'deposition',
  EXAMINATION_UNDER_OATH = 'examination_under_oath',
  HEARING = 'hearing',
  MEET_AND_CONFER = 'meet_and_confer',
}

// limit so the whole CNA document is guaranteed to fit on a single page
export const CNA_TYPE_OTHER_TEXT_CHAR_LIMIT = 300

export const SAVE_REPORTER_FEEDBACK_MUTATION_KEY =
  'save_reporter_feedback_mutation'

// shell constants
export const HEADER_HEIGHT = 56
export const NAVBAR_WIDTH = 240

// FAQ
export const FAQ_URL = 'https://parrot-digital-reporters.helpscoutdocs.com/'
// In-Event Support
export const IN_EVENT_SUPPORT_URL =
  'https://parrot-digital-reporters.helpscoutdocs.com/collection/213-in-event-support'
export enum ScriptStep {
  PREPARATION = 'preparation',
  ATTENDANCE = 'attendance',
  OPENING = 'opening',
  QUESTIONING = 'questioning',
  CLOSING = 'closing',
  REVIEW = 'review',
}

export enum ScriptStepStatus {
  INCOMPLETE = 'incomplete',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
}

export const ATTENDEE_NAME_IGNORED_WORDS = [
  'group',
  'law',
  'and',
  'associates',
  'interpreter',
  'spanish',
  'creole',
  'firm',
  'corporate',
  'defendant',
  'defendant’s',
  'representative',
  'field',
  'desk',
  'adjuster',
  'esq',
  'md',
  'asa',
  'and',
  'offices',
  'llc',
  'association',
  'associates',
  'pa',
  'engineer',
  'for',
  'supervisor',
  'parent',
  'guardian',
  'office',
  'of',
  'assoc',
  'lt',
  'p.a.',
  'pa',
  'pllc',
  'id',
  'pl',
  'legal',
  'the',
  // these words are exception and are not ignored on Reporters frontend
  //'jr',
  //'sr',
]

export const MAX_ATTENDEE_NAME_LENGTH = 30

export enum MediaType {
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT_ROUGH_DRAFT = 'document_rough_draft',
  DOCUMENT_CERTIFIED = 'document_certified',
  DEPOSITION_NOTICE = 'deposition_notice',
}

export const EVENT_TYPE_SHORT_LABEL = {
  [EventType.DEPOSITION]: 'deposition',
  [EventType.EXAMINATION_UNDER_OATH]: 'examination under oath',
  [EventType.HEARING]: 'hearing',
  [EventType.MEET_AND_CONFER]: 'meet and confer',
}

export const EVENT_TYPE_LONG_LABEL = {
  [EventType.DEPOSITION]: 'Deposition',
  [EventType.EXAMINATION_UNDER_OATH]: 'Examination Under Oath',
  [EventType.HEARING]: 'Hearing',
  [EventType.MEET_AND_CONFER]: 'Meet and Confer',
}

export enum ParrotContact {
  SUPPORT_EMAIL = 'support@parrot.us',
  EXHIBITS_EMAIL = 'exhibits@parrot.us',
  ADDRESS = '169 Madison Avenue, #2380 New York, NY',
}

export const STALE_TIME_EVENT_QUERY = 30 * 60 * 1000 // 30 minutes

// Zoom Livestream Bot
// note: this name is duplicated on backend, if you are changing it here then please update it there as well
export const ZOOM_LIVESTREAM_BOT_NAME = 'Reporter Backup Device' as const

export const TIME_FORMAT = 'h:mm A'

export enum ExhibitDesignation {
  PLAINTIFF = 'plaintiff',
  DEFENDANT = 'defendant',
}

export enum ReorderExhibitDirection {
  UP = 'up',
  DOWN = 'down',
}

export const ExhibitDesignationLabels = {
  [ExhibitDesignation.PLAINTIFF]: "Plaintiff's Exhibit",
  [ExhibitDesignation.DEFENDANT]: "Defendant's Exhibit",
}
